import React, { useEffect, useState,useRef } from 'react';
import { Row, Col, Container, Button, Form  } from 'react-bootstrap';
import './UserCustomerServices.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import {validateusercusservice} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const UserCustomerServices = () => {
	const inputRef = useRef(null);
const navigate = useNavigate();
const [errors,setErrors] = useState({});

const [inputs,setInputs] = useState({customer_id:'',name:'',email:'',phone_number:'',country_short_code:'us',country_code:'1',message:''});
const [loading,setLoading] = useState(false);	

useEffect(() => {
      document.title = `User Contact Customer Services | Blue Faucet`;
      document.getElementById('bodywrap').className='userpgmain user-icn';

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 

/* Start get profile */
let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	API.post('get_profile', formData)
		.then(response => {
			setLoading(false);
		if(response.data.status === 0){
			
		}else{
			setInputs({
			  customer_id:response.data.data.customers_id,
			  name: response.data.data.name,
			  email: response.data.data.email,
			  country_code:response.data.data.country_code,
			  country_short_code:response.data.data.country_short_code,
			  phone_number:response.data.data.mobile,
			  message:''
		  });
		  
		  const input = inputRef.current;
				const iti = intlTelInput(input, {
					initialCountry:response.data.data.country_short_code,
				  separateDialCode:true,
				});
				// Listen for changes
				input.addEventListener('countrychange', () => {
				  const selectedCountryData = iti.getSelectedCountryData();
				  var country_code_lbl='country_code';
				  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
				  var country_short_code_lbl='country_short_code';
				  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
				});

				// Clean up when the component unmounts
				return () => {
				  iti.destroy();
				};
		}                  
	}).catch(error => {
		 
	});  
 /* End get profile */

},[])  


 /* Start Value set */
  const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
    }
  /* End Value set */
/* Start Submit Sign */
	const handleSubmit = (event) => {
		
		event.preventDefault();
		
		const validationErrors = validateusercusservice(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
		
		
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let customer_id=inputs.customer_id;
			let name=inputs.name;
			let email=inputs.email;
			let phone_number=inputs.phone_number;
			let country_code=inputs.country_code;
			let country_short_code=inputs.country_short_code;
			let message=inputs.message;
			
			
			let formData = new FormData();
			formData.append('token', localStorage.getItem("token"));			
			formData.append('customer_id', customer_id);
			formData.append('name', name);
			formData.append('email', email);
			formData.append('phone_number', phone_number);
			formData.append('country_code', country_code);
			formData.append('country_short_code', country_short_code);
			formData.append('message',message);
			API.post('add_contact_customer_service', formData)
                .then(response => {
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
					setLoading(false); 
                }else{
					gettoastsuccess(response.data.message,'/contact-customer-services');					
                    setLoading(false); 
				   //navigate('/login');
                }                  
            }).catch(error => {
                 setLoading(false);
				 gettoastwarning("Something went wrong. Please try again later.",'');
			});  
		}
   } 
  /* End Submit Sign */


    return (
        <>
        {/* ========== User Profile Page Start ========== */}
          <section className="gen-main">
		  <ToastContainer />
              <Row className="maincnttpnw mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                <button onClick={() => navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center"><h3>Contact Customer Services</h3></Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
               <Container>
                <Row md={12} className="genscrn">
                  <Col>
                     <Row md={12} className="maincntsrcn">
                      <Col className="px-0">
                        <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                          <Form.Group className="mb-3">
                            <Form.Label>Customer ID<span>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Customer ID" name="customer_id" value={inputs.customer_id} disabled={true} />
							{errors.customer_id && (<p className="text-danger">{errors.customer_id}</p>)}
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Your Name<span>*</span></Form.Label>
                            <Form.Control type="text" placeholder="Enter Your Name" name="name" value={inputs.name} disabled={true} />
							{errors.name && (<p className="text-danger">{errors.name}</p>)}
						  </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Phone Number <span>*</span></Form.Label>                         
                               <Form.Control type="tel" name="phone_number" id="phone" placeholder="000 000 0000" ref={inputRef} value={inputs.phone_number} disabled={true} />    
							{errors.phone_number && (<p className="text-danger">{errors.phone_number}</p>)}							   
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Email<span>*</span></Form.Label>
                            <Form.Control type="email" placeholder="Enter your email address" name="email" value={inputs.email} disabled={true} />
							{errors.email && (<p className="text-danger">{errors.email}</p>)}	
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label>Your Message<span>*</span></Form.Label>
                            
							<Form.Control as="textarea" rows={3} placeholder="Enter Your Message" name="message" value={inputs.message} onChange={handleInputChange}/>
							{errors.message && (<p className="text-danger">{errors.message}</p>)}
                          </Form.Group>
                          <Row>
                            <Col className="btn-dv">
                             <Button type="submit" variant="">Submit</Button>
                            </Col>                       
                          </Row>
                        </Form>
                      </Col>
                     </Row>
                  </Col>
            
                </Row>
				{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
               </Container>
          </section>
          {/* ========== User Profile Page En ========== */}
        </>
    )
}
export default UserCustomerServices;