import React, { useEffect,useState } from 'react';
import { Row, Col, Container, Accordion,NavLink } from 'react-bootstrap';
import './MerHelp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "../navbar";
import API from '../../../Utils/Api';
import loader from '../../../assests/loader.gif';

const MerHelp = () => {
const [loading,setLoading] = useState(true);
const [help, setHelp] = useState([]);
useEffect(() => {
      document.title = `Merchant Help | Blue Faucet`;
      document.getElementById('bodywrap').className='mhelppgmain grnbg grenicn';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	/* Start get help */
	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('type', 'merchant');
		API.post('get_help', formData)
			.then(response => {
			setLoading(false);	
			if(response.data.status === 0){
				
			}else{
				setHelp(response.data.data.help);
			}                  
		}).catch(error => {
			 
		});  
	/* End get help */
},[])  
    return (
      
        <>
        {/* ========== Merchant Help Page Start ========== */}
         <section className="gen-wrap">
        {/* ========== Navbar Start ========== */}
         <Navbar headerText={'Help'}/>
        {/* ========== Navbar End ========== */}
         <Container>
            <Row className="mx-0 mianhmdwncnt">
               <Col className="mnhmcnt">
               <Col md={12} xs={12} className="py-3">
			   
			    <Row className="mx-0">
                 	<Col md={12} className="text-center merccs-btn">
                 		<NavLink href="/mer-contact-customer-services">Contact Customer Services</NavLink>
                 	</Col>
                 </Row>
			   
               <Row className="mx-0">
        {/* ========== Help Content Start ========== */}
                  <Col md={12} xs={12} className="px-0 usrhlp">
                  <Accordion defaultActiveKey={0}>
					  {help && help.map((val,index) => {
						return(
						   <Accordion.Item eventKey={index}>
							  <Accordion.Header>{val.title} </Accordion.Header>
							  <Accordion.Body>
								 {val.description}
							  </Accordion.Body>
						   </Accordion.Item>
					   )
					  })}
                    </Accordion>
                  </Col>
        {/* ========== Help Content End ========== */}
               </Row>
               </Col>
               </Col>
            </Row>
			{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
         </Container>
        </section>
        {/* ========== Merchant Help Page End ========== */}
        </>
    )
}
export default MerHelp;