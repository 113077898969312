import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import PlacesAutocomplete, { geocodeByAddress,getLatLng } from 'react-places-autocomplete';
import API from '../../Utils/Api';
import { QRCodeSVG } from 'qrcode.react';


const Navbar = (props) => {
const navigate = useNavigate();

const [latitude, setLatitude] = useState(null);
const [longitude, setLongitude] = useState(null);
const [address, setAddress] = useState("");

const [inputslnk,setInputslnk] = useState({url:''});


// const [zipcode, setZipcode] = useState("");

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [show1, setShow1] = useState(false);
const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);
const [suggestions, setSuggestions] = useState([]);

const [noticount, setNoticount] = useState(false);
const searchOptions = {
    componentRestrictions: { country: 'us' }
  };
useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    });   
	
	 var url=process.env.REACT_APP_BASE_URL+'signup?key='+localStorage.getItem("token")+'&type=direct';
	setInputslnk({ url:url }); 
		  
	
	
    // Get the current position using the browser's geolocation API
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
		update_location(position.coords.latitude,position.coords.longitude);
      },
      (error) => {
        console.error("Error getting location:", error.message);
      }
    );
	
	const noticount = () => {
	/* Start get notification count */
	let formData1 = new FormData(); 
		formData1.append('token', localStorage.getItem("token"));
		API.post('notification_count', formData1)
			.then(response => {
		setNoticount(false);
			if(response.data.data > 0){
				setNoticount(true);
			}                
		}).catch(error => {
			 
		});  
	 /* End get notification count */
	}
	noticount();
	const intervalId = setInterval(noticount, 5000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
	
  }, []);
 const  update_location= (lat,lng) => {
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		formData.append('current_lat', lat);
		formData.append('current_long', lng);
		API.post('update_location', formData)
			.then(response => {
		
			if(response.data.status === 0){
				
			}else{
			 			   
			}                  
		}).catch(error => {
			 
		});  
 }	 
useEffect(() => {
    if (latitude && longitude) {
      // Fetch the address and zip code using the Google Maps Geocoding API
      const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
			
          if (data.results.length > 0) {
			  
			  
            var com1='';
			var com2='';
			var com3='';
			var com4='';
			
			for (var i = 0; i < data.results[0].address_components.length; i++) {
			
			  for (var j = 0; j < data.results[0].address_components[i].types.length; j++) {
				 
				if (data.results[0].address_components[i].types[j] === "administrative_area_level_3") { 
				  com1=data.results[0].address_components[i].long_name;
				}
				if (data.results[0].address_components[i].types[j] === "administrative_area_level_1") {
				  com2=data.results[0].address_components[i].long_name;
				}
				if (data.results[0].address_components[i].types[j] === "country") {
				  com3=data.results[0].address_components[i].long_name;
				}
				if (data.results[0].address_components[i].types[j] === "postal_code") {
				  com4=data.results[0].address_components[i].long_name;
				}
			  }
		}
			var add=com1+' '+com2+' '+com3+' '+com4;
			
			setAddress(add);
            // Find the zip code in the address components
            const addressComponents = data.results[0].address_components;
            const zipCodeComponent = addressComponents.find(
              (component) => component.types.includes("postal_code")
            );
            if (zipCodeComponent) {
              // setZipcode(zipCodeComponent.long_name);
            } else {
              // setZipcode("N/A");
            }
          } else {
            setAddress("Address not found");
            // setZipcode("N/A");
          }
        })
        .catch((error) => {
          console.error("Error fetching address:", error.message);
        });
    }
  }, [latitude, longitude]);

  const handleSelect = async (address) => {
  try {
	  setAddress(address);
    const results = await geocodeByAddress(address);
	const latLng = await getLatLng(results[0]);
	let lat=latLng.lat;
	  let lng=latLng.lng;
	  
	  setLatitude(lat);
      setLongitude(lng);
	 update_location(lat,lng);
    // Do something with the results (e.g., get latitude and longitude)
  } catch (error) {
    console.error('Error geocoding address:', error);
  }
};
 //{/* ========== Nevbar Start ========== */}
 
 /* Start product search keyword wise */
const handleInputChangeauto = (event) => {

	let formData = new FormData(); 
	formData.append('token', localStorage.getItem("token"));
	formData.append('keyword', event.target.value);
	API.post('get_search_order', formData)
		.then(response => {
		
		if(response.data.status === 0){
			
		}else{
			setSuggestions(response.data.data);
			
			
		}                  
	}).catch(error => {
		 
	});  
};
/* End product search keyword wise */
/* Start product search autocomplete */
const handlesuggestions = (id,status) => {
	if(status===0)
	{
		window.location.href = '/mer-order-detail/'+id;
	}else if(status===2){
		window.location.href = '/mer-order-detail/'+id;
	}else if(status===1){
		window.location.href = '/mer-receipts/'+id;	
	}else if(status===8){
		window.location.href = '/mer-receipts/'+id;	
	}else if(status===5){
		window.location.href = '/mer-refund-receipts/'+id;	
	}else if(status===7){
		window.location.href = '/mer-refund-request/'+id;	
	}else
	{
		window.location.href = '/mer-order-detail/'+id;
	}
	
	
}
/* End product search autocomplete */ 
 
 return (
        <>
         <Row className="nvcus-main">
         <Col md={12} className="mb-2 tpnv-cntnon">
         <Row className="mx-0 w-100">
          <Col xs={6} sm={6} md={6} className="px-0 text-start"><img src="assets/images/logo.png" className="img-fluid mobail_logo_set" alt="Logo"/></Col>
          <Col xs={6} sm={6} md={6} className="px-0 text-end">
          <div className="demo">
          <NavLink to="#" onClick={handleShow}>
          <i className="location_icon">
          <img src={process.env.REACT_APP_BASE_URL + '/assets/images/location.svg'} className="img-fluid" alt="Logo"/>
          </i>
          <span className="text-white ps-1">{address}</span>
          </NavLink>
         </div>
          </Col>
         </Row>
         </Col>
         <Col md={1} xs={1} className="align-self-center bcnv-arwbtn px-0">
         <button onClick={() =>
            navigate(-1)} className="back-btn">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
               <path d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28533 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
            </svg>
         </button>
         </Col>
         <Col md={9} xs={7} className="align-self-center">
         <div className="constrain hide-inpt">
            <input className="input-elevated" type="text" placeholder="Search here.." onChange={handleInputChangeauto} />
			{
		suggestions.length >0 ?(
		<ul className="product-autosug">
		{suggestions.map((val) => {
          return (
         <li><NavLink to="#" onClick={() =>handlesuggestions(val.id,val.status)}>{val.order_number}</NavLink></li>
		 )
		})
		}
      </ul>
	  ): null
	}
         </div>
         <h3 className="showtxt cnttxtnev">{props.headerText}</h3>
         </Col>
		 <Col md={1} xs={2} className="align-self-center nvbtn_qr">
		 
			<Button className="mdsbrdshow" href={void(0)} variant="" onClick={handleShow1}><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/qr.svg'} className="img-fluid" alt="Logo"/></Button>
		 </Col>
		 
         <Col md={1} xs={2} className="align-self-center nvbtn">
         <Button className="grnshw" href={void(0)} variant="">{/*<img src={process.env.REACT_APP_BASE_URL + '/assets/images/merchant/svg/plus-icn.svg'} className="img-fluid" alt="Logo"/>*/}</Button>{' '}
         <Button className="mdsbrdshow" href="mer-notification" variant=""><img src={process.env.REACT_APP_BASE_URL + '/assets/images/svg/notiicn.svg'} className="img-fluid" alt="Logo"/>{noticount && <span></span> }</Button>{' '}
         </Col>
{/* ========== Popup Invitation Form Start ========== */}
         <div className="popupwrng">
           <Modal show={show} onHide={handleClose} className="popupwrngcnt">
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                 <Col md={12} xs={12} className="mb-3 sercpop">
                    <Form.Label className="fw-normal">Ex: Zipcode/City/State `<span></span></Form.Label>
                    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect} searchOptions={searchOptions}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input {...getInputProps({ placeholder: 'Enter address' })} />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                 </Col>
              </Modal.Body>
              <Modal.Footer className="d-none">
                 <Button variant="secondary" onClick={handleClose}>
                 Close
                 </Button>
                 <Button variant="primary" onClick={handleClose}>
                 Save Changes
                 </Button>
              </Modal.Footer>
           </Modal>
		   
		   
		   <Modal show={show1} onHide={handleClose1} className="popupwrngcnt">
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                 <Col md={12} xs={12} className="mb-3 sercpop text-center">
                    <QRCodeSVG
								value={inputslnk.url}
								width={200}
								height={200}
							  />
                 </Col>
              </Modal.Body>
              <Modal.Footer className="d-none">
                 <Button variant="secondary" onClick={handleClose}>
                 Close
                 </Button>
                 <Button variant="primary" onClick={handleClose}>
                 Save Changes
                 </Button>
              </Modal.Footer>
           </Modal>
		   
        </div>
{/* ========== Popup Invitation Form End ========== */}
        </Row>
        </>
  )
}
export default Navbar;
//{/* ========== Nevbar End ========== */}