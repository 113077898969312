import { Route, Routes, BrowserRouter as Router  } from "react-router-dom";
import { gapi } from "gapi-script";
import React, {useEffect} from 'react';
import PrivateRoleLoginRoute from "./Utils/PrivateRoleLoginRoute";
import PrivateRoute from "./Utils/PrivateRoute";
import PrivateLoginRoute from "./Utils/PrivateLoginRoute";
import PrivateRouteMer from "./Utils/PrivateRouteMer";
import PrivateLoginRouteMer from "./Utils/PrivateLoginRouteMer";
import PrivateRouteEmp from "./Utils/PrivateRouteEmp";
import PrivateLoginRouteEmp from "./Utils/PrivateLoginRouteEmp";

import SelectRole from './Pages/User/SelectRole/SelectRole';
import UserHome from './Pages/User/UserHome/UserHome';
import UserVideo from './Pages/User/UserVideo/UserVideo';
import UserSgup from './Pages/User/UserSgup/UserSgup';
import UserLogin from './Pages/User/UserLogin/UserLogin';
import UserFrgt from './Pages/User/UserFrgt/UserFrgt';
import UserOtp from './Pages/User/UserOtp/UserOtp';
import UserPwd from './Pages/User/UserPwd/UserPwd';
import UserPrfl from './Pages/User/UserPrfl/UserPrfl';
import UserPymt from './Pages/User/UserPymt/UserPymt';
import UserPymtafterlogin from './Pages/User/UserPymtafterlogin/UserPymtafterlogin';
import UserCtgrylst from './Pages/User/UserCtgrylst/UserCtgrylst';
import UserStrlst from './Pages/User/UserStrlst/UserStrlst';
import UserStrdtl from './Pages/User/UserStrdtl/UserStrdtl';
import UserOrdrsmry from './Pages/User/UserOrdrsmry/UserOrdrsmry';
import UserOrdrsmrynew from './Pages/User/UserOrdrsmry/UserOrdrsmrynew';
import UserOrdrsmrynewstp2 from './Pages/User/UserOrdrsmry/UserOrdrsmrynewstp2';
import UserOrdrsmrynewstp3 from './Pages/User/UserOrdrsmry/UserOrdrsmrynewstp3';

import UserOdrcfm from './Pages/User/UserOrdrsmry/UserOdrcfm';
import UserOdrcfmnew from './Pages/User/UserOrdrsmry/UserOdrcfmnew';
import UserCupn from './Pages/User/UserCupn/UserCupn';
import UserOrdpymt from './Pages/User/UserOrdpymt/UserOrdpymt';
import UserPrflstp from './Pages/User/UserPrflstp/UserPrflstp';
import UserFvrt from './Pages/User/UserFvrt/UserFvrt';
import UserFvrtproduct from './Pages/User/UserFvrtproduct/UserFvrtproduct';
import UserNoti from './Pages/User/UserNoti/UserNoti';
import UserIntmrct from './Pages/User/UserIntmrct/UserIntmrct';
import UserHelp from './Pages/User/UserHelp/UserHelp';
import UserCustomerServices from './Pages/User/UserCustomerServices/UserCustomerServices';
import UserAbout from './Pages/User/UserAbout/UserAbout';
import UserTerms from './Pages/User/UserTerms/UserTerms';
import UserPrvcy from './Pages/User/UserPrvcy/UserPrvcy';
import UserDeleteAccount from './Pages/User/UserDeleteAccount/UserDeleteAccount';

import UserSrvc from './Pages/User/UserSrvc/UserSrvc';
import UserPrdct from './Pages/User/UserPrdct/UserPrdct';
import UserEplyscn from './Pages/User/UserEplyscn/UserEplyscn';
import UserCupnscrn from './Pages/User/UserCupnscrn/UserCupnscrn';
import UserMembershipInvitation from './Pages/User/UserMembershipInvitation/UserMembershipInvitation';

import UserInvic from './Pages/User/UserInvic/UserInvic';
import UserInvicProduct from './Pages/User/UserInvicProduct/UserInvicProduct';
import UserRcipt from './Pages/User/UserRcipt/UserRcipt';
import UserOrdersummary from './Pages/User/UserRcipt/UserOrdersummary';
import UserRefundRcipt from './Pages/User/UserRcipt/UserRefundRcipt';


import UserOrdersummaryservice from './Pages/User/UserRcipt/UserOrdersummaryservice';
import UserRefundRciptService from './Pages/User/UserRcipt/UserRefundRciptService';


import UserRciptService from './Pages/User/UserRcipt/UserRciptService';
import UserCalender from './Pages/User/UserCalender/UserCalender';
import UserCalenderprod from './Pages/User/UserCalenderprod/UserCalenderprod';
import UserMycldr from './Pages/User/UserMycldr/UserMycldr';

import MerLogin from './Pages/Merchant/MerLogin/MerLogin';
import MerForgot from './Pages/Merchant/MerForgot/MerForgot';
import MerOtp from './Pages/Merchant/MerOtp/MerOtp';
import MerPsw from './Pages/Merchant/MerPsw/MerPsw';
import MerPrivacy from './Pages/Merchant/MerPrivacy/MerPrivacy';
import MerTerms from './Pages/Merchant/MerTerms/MerTerms';
import MerStore from './Pages/Merchant/MerStore/MerStore';
import MerStripeAccountSetup from './Pages/Merchant/MerStripeAccountSetup/MerStripeAccountSetup';
import MerAfterLoginStripeAccountSetup from './Pages/Merchant/MerAfterLoginStripeAccountSetup/MerAfterLoginStripeAccountSetup';
import MerVideo from './Pages/Merchant/MerVideo/MerVideo';
import MerProfile from './Pages/Merchant/MerProfile/MerProfile';
import MerDash from './Pages/Merchant/MerDash/MerDash';
import MerCust from './Pages/Merchant/MerCust/MerCust';

import MerCustOrder from './Pages/Merchant/MerCustOrder/MerCustOrder';

import MerExistingCust from './Pages/Merchant/MerExistingCust/MerExistingCust';
import MerMarketing from './Pages/Merchant/MerMarketing/MerMarketing';
import MerSelling from './Pages/Merchant/MerSelling/MerSelling';
import MerNoti from './Pages/Merchant/MerNoti/MerNoti';
import MerAbout from './Pages/Merchant/MerAbout/MerAbout';
import MerHelp from './Pages/Merchant/MerHelp/MerHelp';
import MerCustomerServices from './Pages/Merchant/MerCustomerServices/MerCustomerServices';
import MerCal from './Pages/Merchant/MerCal/MerCal';
import MerOrder from './Pages/Merchant/MerOrder/MerOrder';
import MerInvoice from './Pages/Merchant/MerInvoice/MerInvoice';
import MerCoupon from './Pages/Merchant/MerCoupon/MerCoupon';
import Coupon from './Pages/Merchant/Coupon/Coupon';
import MerSubscr from './Pages/Merchant/MerSubscr/MerSubscr';
import MerReceipts from './Pages/Merchant/MerReceipts/MerReceipts';
import MerOrderdetail from './Pages/Merchant/MerReceipts/MerOrderdetail';
import MerRefundRequest from './Pages/Merchant/MerReceipts/MerRefundRequest';
import MerRefundReceipts from './Pages/Merchant/MerReceipts/MerRefundReceipts';
import MerSubreceiptsHistory from './Pages/Merchant/MerSubreceiptsHistory/MerSubreceiptsHistory';
import MerSubreceipts from './Pages/Merchant/MerSubreceipts/MerSubreceipts';

import MerEmpSubreceiptsHistory from './Pages/Merchant/MerEmpSubreceiptsHistory/MerEmpSubreceiptsHistory';
import MerEmpSubreceipts from './Pages/Merchant/MerEmpSubreceipts/MerEmpSubreceipts';


import MerOrderStatistics from './Pages/Merchant/MerOrderStatistics/MerOrderStatistics';
import MerIncomeForecast from './Pages/Merchant/MerIncomeForecast/MerIncomeForecast';
import MerCouponRevenue from './Pages/Merchant/MerCouponRevenue/MerCouponRevenue';
import MerMembershipStatistics from './Pages/Merchant/MerMembershipStatistics/MerMembershipStatistics';

import MerEmp from './Pages/Merchant/MerEmp/MerEmp';

import EmpCale from './Pages/Employee/EmpCale/EmpCale';
import EmpCommi from './Pages/Employee/EmpCommi/EmpCommi';
import EmpWithdrawalHis from './Pages/Employee/EmpWithdrawalHis/EmpWithdrawalHis';
import EmpDash from './Pages/Employee/EmpDash/EmpDash';
import EmpForget from './Pages/Employee/EmpForget/EmpForget';
import EmpInvoice from './Pages/Employee/EmpInvoice/EmpInvoice';
import EmpLogin from './Pages/Employee/EmpLogin/EmpLogin';
import EmpNoti from './Pages/Employee/EmpNoti/EmpNoti';
import EmpOrder from './Pages/Employee/EmpOrder/EmpOrder';
import EmpOtp from './Pages/Employee/EmpOtp/EmpOtp';
import EmpProfile from './Pages/Employee/EmpProfile/EmpProfile';
import EmpProfileafterlogin from './Pages/Employee/EmpProfileafterlogin/EmpProfileafterlogin';
import EmpPwd from './Pages/Employee/EmpPwd/EmpPwd';
import EmpReceipts from './Pages/Employee/EmpReceipts/EmpReceipts';
import EmpOrderdetail from './Pages/Employee/EmpReceipts/EmpOrderdetail';

import EmpRefundRequest from './Pages/Employee/EmpReceipts/EmpRefundRequest';
import EmpRefundReceipts from './Pages/Employee/EmpReceipts/EmpRefundReceipts';

import EmpAfterLoginStripeAccountSetup from './Pages/Employee/EmpAfterLoginStripeAccountSetup/EmpAfterLoginStripeAccountSetup';

import EmpOrderStatistics from './Pages/Employee/EmpOrderStatistics/EmpOrderStatistics';

import EmpIncomeForecast from './Pages/Employee/EmpIncomeForecast/EmpIncomeForecast';

import SalVideo from './Pages/Sales/SalVideo/SalVideo';
import SalDash from './Pages/Sales/SalDash/SalDash';
import SalHelp from './Pages/Sales/SalHelp/SalHelp';
import SalAbout from './Pages/Sales/SalAbout/SalAbout';
import SalSignup from './Pages/Sales/SalSignup/SalSignup';
import SalWithdraw from './Pages/Sales/SalWithdraw/SalWithdraw';
import SalCommi from './Pages/Sales/SalCommi/SalCommi';
import SalWithdrawalHis from './Pages/Sales/SalWithdrawalHis/SalWithdrawalHis';
import SalProfile from './Pages/Sales/SalProfile/SalProfile';
import SalNoti from './Pages/Sales/SalNoti/SalNoti';
import SalPerson from './Pages/Sales/SalPerson/SalPerson';
import SalInvite from './Pages/Sales/SalInvite/SalInvite';
import SalAfterLoginStripeAccountSetup from './Pages/Sales/SalAfterLoginStripeAccountSetup/SalAfterLoginStripeAccountSetup';



function App() {
	const initializeGapi = () => {
    gapi.client.init({
      clientId: process.env.REACT_APP_GMAIL_CLIENT_ID,
      scope: "",
    });
  };
  
  useEffect(() =>{
    // load and init google api scripts
    gapi.load("client:auth2", initializeGapi);
  })
	
  return (
    <>
      <Router>
              <Routes>
			    <Route exact element={<PrivateRoleLoginRoute />}>
				  <Route exact path="/" element={<SelectRole />} />
				 </Route>
				  
				  <Route exact element={<PrivateLoginRoute />}>
					<Route exact path="/signup/" element={<UserSgup />} />
					<Route exact path="/login/" element={<UserLogin />} />
					<Route exact path="/forgot-password/" element={<UserFrgt />} />
					<Route exact path="/otp/" element={<UserOtp />} />
					<Route exact path="/create-password/" element={<UserPwd />} />
				  </Route>
                  <Route exact element={<PrivateRoute />}> 
				    <Route exact path="/user-video/" element={<UserVideo />} />
				    <Route exact path="/profile-setup/" element={<UserPrflstp />} />
					<Route exact path="/payment/" element={<UserPymt />} />
					<Route exact path="/profile/" element={<UserPrfl />} />
					<Route exact path="/home/" element={<UserHome />} />
					<Route exact path="/payment-after-login/" element={<UserPymtafterlogin />} />
					<Route exact path="/store-list/:id" element={<UserStrlst />} />
					<Route exact path="/category-list/" element={<UserCtgrylst />} />
					<Route exact path="/favorite/" element={<UserFvrt />} />
					<Route exact path="/favorite-product/" element={<UserFvrtproduct />} />
					<Route exact path="/store-details/:id" element={<UserStrdtl />} />
					<Route exact path="/product/:id" element={<UserPrdct />} />
					<Route exact path="/service/:id" element={<UserSrvc />} />
					<Route exact path="/about/" element={<UserAbout />} />
					<Route exact path="/help/" element={<UserHelp />} />
					<Route exact path="/contact-customer-services/" element={<UserCustomerServices />} />
					<Route exact path="/notification/" element={<UserNoti />} />
					<Route exact path="/membership-invitation/" element={<UserMembershipInvitation />} />

					<Route exact path="/calendar/:id" element={<UserCalender />} />
					<Route exact path="/calendar-prod/:id" element={<UserCalenderprod />} />
					<Route exact path="/order-payment/" element={<UserOrdpymt />} />
					<Route exact path="/invoice/:id" element={<UserInvic />} />
					<Route exact path="/invoice-product/:id" element={<UserInvicProduct />} />
					<Route exact path="/receipts/:id" element={<UserRcipt />} />
					<Route exact path="/order-summary/:id" element={<UserOrdersummary />} />
					
					<Route exact path="/refund-receipt/:id" element={<UserRefundRcipt />} />
					
					<Route exact path="/receipts-service/:id" element={<UserRciptService />} />
					<Route exact path="/order-summary-service/:id" element={<UserOrdersummaryservice />} />
					
					<Route exact path="/refund-receipt-service/:id" element={<UserRefundRciptService />} />
					
					<Route exact path="/service-order-confirmation/:id" element={<UserOrdrsmrynewstp2 />} />
					<Route exact path="/prod-order-confirmation/:id" element={<UserOrdrsmrynewstp3 />} />
					<Route exact path="/product-order-confirmation/" element={<UserOdrcfmnew />} />
					<Route exact path="/coupon-list/" element={<UserCupn />} />
					<Route exact path="/invite-merchant/" element={<UserIntmrct />} />
					<Route exact path="/my-calendar/" element={<UserMycldr />} />
					
					
					<Route exact path="/sales-person/" element={<SalPerson />} />
					<Route exact path="/sales-video/" element={<SalVideo />} />
					<Route exact path="/sales-about/" element={<SalAbout />} />
					<Route exact path="/sales-help/" element={<SalHelp />} />
					<Route exact path="/sales-notification/" element={<SalNoti />} />
					<Route exact path="/sales-signup/" element={<SalSignup />} />
					
					<Route exact path="/sales-profile/" element={<SalProfile />} />
					<Route exact path="/sales-dashboard/" element={<SalDash />} />
					<Route exact path="/sales-commission/" element={<SalCommi />} />
					<Route exact path="/sales-invite-merchant/" element={<SalInvite />} />
					<Route exact path="/sales-withdraw/" element={<SalWithdraw />} />
					<Route exact path="/sales-after-login-stripe-account-setup/" element={<SalAfterLoginStripeAccountSetup />} />
					
					<Route exact path="/sales-withdrawal-history/" element={<SalWithdrawalHis />} />
					
					<Route exact path="/coupon/" element={<UserCupnscrn />} />
                    <Route exact path="/employee/:id" element={<UserEplyscn />} />
					
                    <Route exact path="/order-summary/" element={<UserOrdrsmry />} />                                      
                    <Route exact path="/service-order-summary/" element={<UserOrdrsmrynew />} />     
                    <Route exact path="/order-confirmation/" element={<UserOdrcfm />} />
					
				  </Route>
				  
					<Route exact path="/delete-account/" element={<UserDeleteAccount />} />
					<Route exact path="/privacy-policy/" element={<UserPrvcy />} />
					<Route exact path="/terms/" element={<UserTerms />} />

                  <Route exact element={<PrivateLoginRouteMer />}>
					<Route exact path="/mer-login/" element={<MerLogin />} />
					<Route exact path="/mer-forgot-password/" element={<MerForgot />} />
					<Route exact path="/mer-otp/" element={<MerOtp />} />
                    <Route exact path="/mer-create-password/" element={<MerPsw />} />
					<Route exact path="/mer-store-setup/" element={<MerStore />} />
				  </Route>
				  
				  <Route exact element={<PrivateRouteMer />}> 
				   <Route exact path="/mer-video/" element={<MerVideo />} />
				    <Route exact path="/mer-stripe-account-setup/" element={<MerStripeAccountSetup />} />
				    <Route exact path="/mer-profile/" element={<MerProfile />} />
					<Route exact path="/mer-store/" element={<MerSelling />} />
					<Route exact path="/mer-dashboard/" element={<MerDash />} />
					<Route exact path="/mer-about/" element={<MerAbout />} />
					<Route exact path="/mer-help/" element={<MerHelp />} />
					<Route exact path="/mer-contact-customer-services/" element={<MerCustomerServices />} />
					
					<Route exact path="/mer-notification/" element={<MerNoti />} />
					<Route exact path="/mer-subscription/" element={<MerSubscr />} />
					<Route exact path="/mer-subscription-history/" element={<MerSubreceiptsHistory />} />
					<Route exact path="/mer-subscription-receipt/:id/:sub_id" element={<MerSubreceipts />} />
					
					<Route exact path="/mer-emp-subscription-history/" element={<MerEmpSubreceiptsHistory />} />
					<Route exact path="/mer-emp-subscription-receipt/:id/:emp_id" element={<MerEmpSubreceipts />} />
					
					<Route exact path="/mer-employee-management/" element={<MerEmp />} />
					<Route exact path="/mer-after-login-stripe-account-setup/" element={<MerAfterLoginStripeAccountSetup />} />
					
					<Route exact path="/mer-order-appointment/" element={<MerOrder />} />
					
					<Route exact path="/mer-order-appointment-statistics/" element={<MerOrderStatistics />} />
					
					<Route exact path="/mer-income-forecast/" element={<MerIncomeForecast />} />
					
					<Route exact path="/mer-coupon-revenue/" element={<MerCouponRevenue />} />
					
					<Route exact path="/mer-membership-statistics/" element={<MerMembershipStatistics />} />
					
					<Route exact path="/mer-calendar/" element={<MerCal />} />
					
					<Route exact path="/mer-receipts/:id" element={<MerReceipts />} />
					<Route exact path="/mer-order-detail/:id" element={<MerOrderdetail />} />
					
					<Route exact path="/mer-refund-request/:id" element={<MerRefundRequest />} />
					
					<Route exact path="/mer-refund-receipts/:id" element={<MerRefundReceipts />} />
					
					<Route exact path="/mer-customer/" element={<MerCust />} />
					<Route exact path="/mer-customer-order/:id" element={<MerCustOrder />} />
					
					<Route exact path="/mer-existing-customer/" element={<MerExistingCust />} />
					
					
					<Route exact path="/mer-invoice/:id" element={<MerInvoice />} />
					
					<Route exact path="/mer-sending-coupons/" element={<MerCoupon />} />
					
					<Route exact path="/create-coupon/" element={<Coupon />} />
				  </Route>
				  
                  <Route exact path="/mer-terms/" element={<MerTerms />} />
                  <Route exact path="/mer-privacy/" element={<MerPrivacy />} />

                  <Route exact path="/mer-marketing/" element={<MerMarketing />} />
                  
				  <Route exact element={<PrivateLoginRouteEmp />}>
					 <Route exact path="/emp-login/" element={<EmpLogin />} />
					 <Route exact path="/emp-forgot-password/" element={<EmpForget />} />
					 <Route exact path="/emp-otp/" element={<EmpOtp />} />
					 <Route exact path="/emp-create-password/" element={<EmpPwd />} />
				  </Route>
				  <Route exact element={<PrivateRouteEmp />}> 
				    <Route exact path="/emp-profile/" element={<EmpProfile />} />
					<Route exact path="/emp-dashboard/" element={<EmpDash />} />
					
					<Route exact path="/emp-profile-after-login/" element={<EmpProfileafterlogin />} />
					
					<Route exact path="/emp-notification/" element={<EmpNoti />} />
					
					<Route exact path="/emp-calendar/" element={<EmpCale />} />
					
					<Route exact path="/emp-order/" element={<EmpOrder />} />
					
					<Route exact path="/emp-receipts/:id" element={<EmpReceipts />} />
					
					<Route exact path="/emp-order-detail/:id" element={<EmpOrderdetail />} />
					
					<Route exact path="/emp-refund-request/:id" element={<EmpRefundRequest />} />
					
					<Route exact path="/emp-refund-receipts/:id" element={<EmpRefundReceipts />} />
					
					<Route exact path="/emp-invoice/:id" element={<EmpInvoice />} />
					
					<Route exact path="/emp-after-login-stripe-account-setup/" element={<EmpAfterLoginStripeAccountSetup />} />
					
					<Route exact path="/emp-commission/" element={<EmpCommi />} /> 
					
					<Route exact path="/emp-withdrawal-history/" element={<EmpWithdrawalHis />} /> 
					
					<Route exact path="/emp-order-appointment-statistics/" element={<EmpOrderStatistics />} />
					
					<Route exact path="/emp-income-forecast/" element={<EmpIncomeForecast />} />
					
				  </Route>
			  </Routes>
      </Router>           
    </>
  );
}
export default App;