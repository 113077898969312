import React, { useEffect, useState,useRef } from 'react';
import { Row, Col, Container, Button, Form,Modal  } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserPrfl.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import API from '../../../Utils/Api';
import {validatepro,validateaddressprofile,validateaddressprofileedit,validatelogin} from '../../../Utils/validate';
import PlacesAutocomplete, { geocodeByAddress,getLatLng  } from 'react-places-autocomplete';
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning,removeUserSession } from '../../../Utils/Common';

const UserPrfl = () => {
	
	const [showdeletelogin, setShowDeleteLogin] = useState(false);
	const [showdelete, setShowDelete] = useState(false);
	const [inputslogin,setInputsLogin] = useState({email_or_phone:'',password:''});
	
const currentDate = new Date();

  // Calculate the date 18 years ago from the current date
  const minDate = new Date(currentDate);
  minDate.setFullYear(currentDate.getFullYear() - 18);

  // Convert the minDate to a string in the "yyyy-MM-dd" format
  const minDateStr = minDate.toISOString().split('T')[0];
	
const inputRef = useRef(null);
	
const navigate = useNavigate();
const [error,setError] = useState();
const [errors,setErrors] = useState({});
const [loading,setLoading] = useState(true);
const [loading1,setLoading1] = useState(false);
const [logintype,setLogintype] = useState(false);
const [inputs,setInputs] = useState({customers_id:'',name:'',gender:'',country_short_code:'',country_code:'',mobile:'',dob:'',user_name:'',email:'',password:'',password_confirmation:''});
const [image, setImage] = useState({preview: '', raw: ''});


const [address,setAddress] = useState({title:'',street_name:'',state_id:'',city_id:'',latitude:'',longitude:''});
const [address1, setAddress1] = useState("");
const [disaddress, setDisaddress] = useState([]);



const [values, setValues] = useState({
	password: "",
	showPassword: false,
});
const [values1, setValues1] = useState({
	password: "",
	showPassword: false,
});

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [addressedit,setAddressedit] = useState({idedit:'',titleedit:'',street_nameedit:'',state_idedit:'',city_idedit:'',latitudeedit:'',longitudeedit:'',zip_codeedit:''});
const [address1edit, setAddress1edit] = useState("");

const [showedit, setShowedit] = useState(false);
const handleCloseedit = () => setShowedit(false);
 const searchOptions = {
    componentRestrictions: { country: 'us' }
  };
const [values2, setValues2] = useState({
        password: "",
        showPassword: false,
    });
useEffect(() => {
      document.title = `User Profile | Blue Faucet`;
      document.getElementById('bodywrap').className='userpgmain user-icn';

		window.scrollTo({
		  top: 0,
		  left: 0,
		  behavior: 'smooth', 
		}); 
	/* Start get profile */
	let formData = new FormData(); 
		formData.append('token', localStorage.getItem("token"));
		API.post('get_profile', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
				if(response.data.data.login_type==='')
				{
					setLogintype(true);
				}
			  setInputs({
				  customers_id:response.data.data.customers_id,
				  name: response.data.data.name,
				  gender: response.data.data.gender,
				  mobile: response.data.data.mobile,
				  dob: response.data.data.dob,
				  user_name: response.data.data.user_name,
				  email: response.data.data.email,
				  country_code:response.data.data.country_code,
				  country_short_code:response.data.data.country_short_code
			  });
			  
			  
			  if(response && response.data.data.image !== ""){
					setImage({
						preview : response.data.data.image,
						raw : ''
					});
				}
				setDisaddress(response.data.data.user_address);
				
				
				const input = inputRef.current;
					const iti = intlTelInput(input, {
						initialCountry:response.data.data.country_short_code,
					  separateDialCode:true,
					});
					
					// Listen for changes
					input.addEventListener('countrychange', () => {
					  const selectedCountryData = iti.getSelectedCountryData();
					  var country_code_lbl='country_code';
					  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
					  var country_short_code_lbl='country_short_code';
					  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
					});

					// Clean up when the component unmounts
					return () => {
					  iti.destroy();
					};
				
			}                  
		}).catch(error => {
			 
		});  
	 /* End get profile */
},[])  

/* Start get lat lng */
 const handleSelect = async (addres) => {
	 let add='street_name';
	setAddress(address => ({...address, [add]: addres}));
    setAddress1(addres);
	 try{
      const results = await geocodeByAddress(addres);
	  console.log(results);
	  const postalCodeComponent = results[0].address_components.find(component => component.types.includes('postal_code'));
        if (postalCodeComponent) {
          
		  let zip_codelbl='zip_code';
		  let zip_code=postalCodeComponent.short_name;
			setAddress(address => ({...address, [zip_codelbl]: zip_code}));
        }
		let city_lbl='city_id';
		let state_lbl='state_id';
		setAddress(address => ({...address, [city_lbl]: ''}));
		setAddress(address => ({...address, [state_lbl]: ''}));
		for (var i = 0; i < results[0].address_components.length; i++) {
			
			  for (var j = 0; j < results[0].address_components[i].types.length; j++) {
				 
				if (results[0].address_components[i].types[j] === "locality") {
				  
				  let city=results[0].address_components[i].long_name;
					setAddress(address => ({...address, [city_lbl]: city}));
				}
				if (results[0].address_components[i].types[j] === "administrative_area_level_1") {
				  
				  let state=results[0].address_components[i].long_name;
					setAddress(address => ({...address, [state_lbl]: state}));
				}
			  }
		}
		
	  
      const latLng = await getLatLng(results[0]);
      let latitudelbl='latitude';
	  let longitudelbl='longitude';
	  let lat=latLng.lat;
	  let lng=latLng.lng;
	 setAddress(address => ({...address, [latitudelbl]: lat}));
	 setAddress(address => ({...address, [longitudelbl]: lng}));
    } catch (error) {
    }
 }
/* End get lat lng */
/* Start Address Value set */
const handleAddressInputChange = (event) => {
      event.persist();
      setAddress(address => ({...address, [event.target.name]: event.target.value})); 
}
/* End Address Value set */

/* Start Address Submit profile */
const handleAddressSubmit = (event) => {
	event.preventDefault();
		const validationErrors = validateaddressprofile(address);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let title=address.title;
			let street_name=address.street_name;
			let state_id=address.state_id;
			let city_id=address.city_id;
			let latitude=address.latitude;
			let longitude=address.longitude;
			let zip_code=address.zip_code;

			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('title', title);
			formData.append('street_name', street_name);
			formData.append('state_id', state_id);
			formData.append('city_id', city_id);
			formData.append('latitude', latitude);
			formData.append('longitude',longitude);
			formData.append('zip_code', zip_code);
			
			
			API.post('add_address', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
					setShow(false);
                }else{
					setDisaddress(response.data.data);
					setLoading(false);
				    setShow(false);
					setAddress({title:'',street_name:'',state_id:'',city_id:'',latitude:'',longitude:''});
					setAddress1("");
					
                }                  
            }).catch(error => {
                 setLoading(false);
				 setError("Something went wrong. Please try again later.");
				 setShow(false);
			});  
		}
}
/* End Address Submit profile */
/* Start Address delete */
const handleDelete = (id) => {
	
	setError('');
	setErrors({});
	if(id!=='')
	{
		setLoading(true);
			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('id', id);
			API.post('remove_address', formData)
                .then(response => {
					
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
                }else{
					setDisaddress(response.data.data);
					setLoading(false);	
                }                 
            }).catch(error => {
                 setLoading(false);
				 setError("Something went wrong. Please try again later.");
				 
			});  
	}
}
/* End Address delete */
/* Start Value set */
const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value})); 
}
/* End Value set */
/* Start image set */
const handleChange = (event) => {
	event.preventDefault();
	if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/)) {
	  //alert('Please select valid image.');
	  gettoastwarning('Please select valid image.','');
	}else{
		setImage({
			preview: URL.createObjectURL(event.target.files[0]),
			raw: event.target.files[0]
		});
	}
}
/* Start image set */
/* Start Submit profile */
const handleSubmit = (event) => {
	event.preventDefault();
		const validationErrors = validatepro(inputs,disaddress,logintype);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let user_name=inputs.user_name;
			let name=inputs.name;
			let email=inputs.email;
			let mobile=inputs.mobile;
			let country_code=inputs.country_code;
			let dob=inputs.dob;
			let gender=inputs.gender;
			let password=inputs.password;			
			let password_confirmation=inputs.password_confirmation;
			let country_short_code=inputs.country_short_code;

			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('user_name', user_name);
			formData.append('name', name);
			formData.append('email', email);
			formData.append('mobile', mobile);
			formData.append('country_code', country_code);
			formData.append('dob',dob);
			formData.append('gender', gender);
			formData.append('country_short_code', country_short_code);
			if(image.raw!=='')
			{
				formData.append('profile', image.raw);
			}
			if(password)
			{
				formData.append('password', password);
				formData.append('password_confirmation', password_confirmation);
			}
			API.post('update_profile', formData)
                .then(response => {
					setLoading(false); 
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
                }else{
					//alert(response.data.message);
				   //navigate('/home');
				   gettoastsuccess(response.data.message,'/home');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			});  
		}
}
/* End Submit profile */
 /* Start Password Visible */
 const handleClickShowPassword = () => {
	setValues({ ...values, showPassword: !values.showPassword });
}
 const handleClickShowPassword1 = () => {
	setValues1({ ...values1, showPassword: !values1.showPassword });
}
/* End Password Visible */
 /* Start Phone Number */
const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}
/* End Phone Number */
/* Start Address edit */
const handleEdit = (id) => {
	setLoading(true);
	 let idlbl='idedit';
	setAddressedit(addressedit => ({...addressedit, [idlbl]: id}));
	setShowedit(true);
	
	let formData = new FormData(); 
	   formData.append('token', localStorage.getItem("token"));
	   formData.append('id', id);
		API.post('get_address', formData)
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setAddressedit({
				  idedit:id,
				  titleedit:response.data.data.title,
				  street_nameedit:response.data.data.street_name,
				  state_idedit:response.data.data.state_id,
				  city_idedit:response.data.data.city_id,
				  latitudeedit:response.data.data.lat,
				  longitudeedit:response.data.data.long,
				  zip_codeedit:response.data.data.zip_code
			  });
			  setAddress1edit(response.data.data.street_name);
			}                  
		}).catch(error => {
			 
		});  
	
}
/* End Address edit */
/* Start get lat lng */
 const handleSelectedit = async (addres) => {
	 let add='street_nameedit';
	setAddressedit(addressedit => ({...addressedit, [add]: addres}));
    setAddress1edit(addres);
	 try {
      const results = await geocodeByAddress(addres);
	  
	  const postalCodeComponent = results[0].address_components.find(component => component.types.includes('postal_code'));
        if (postalCodeComponent) {
          
		  let zip_codelbl='zip_codeedit';
		  let zip_code=postalCodeComponent.short_name;
			setAddressedit(addressedit => ({...addressedit, [zip_codelbl]: zip_code}));
        }
	  
	  let city_lbl='city_idedit';
		let state_lbl='state_idedit';
		setAddressedit(addressedit => ({...addressedit, [city_lbl]: ''}));
		setAddressedit(addressedit => ({...addressedit, [state_lbl]: ''}));
		for (var i = 0; i < results[0].address_components.length; i++) {
			
			  for (var j = 0; j < results[0].address_components[i].types.length; j++) {
				 
				if (results[0].address_components[i].types[j] === "locality") {
				  
				  let city=results[0].address_components[i].long_name;
					setAddressedit(addressedit => ({...addressedit, [city_lbl]: city}));
				}
				if (results[0].address_components[i].types[j] === "administrative_area_level_1") {
				  
				  let state=results[0].address_components[i].long_name;
					setAddressedit(addressedit => ({...addressedit, [state_lbl]: state}));
				}
			  }
		}
	  
      const latLng = await getLatLng(results[0]);
      let latitudelbl='latitudeedit';
	  let longitudelbl='longitudeedit';
	  let lat=latLng.lat;
	  let lng=latLng.lng;
	 setAddressedit(addressedit => ({...addressedit, [latitudelbl]: lat}));
	 setAddressedit(addressedit => ({...addressedit, [longitudelbl]: lng}));
    } catch (error) {
    }
 }
/* End get lat lng */
/* Start Edit Address Value set */
const handleAddressInputChangeedit = (event) => {
      event.persist();
      setAddressedit(addressedit => ({...addressedit, [event.target.name]: event.target.value})); 
}
/* End Edit Address Value set */

/* Start Edit Address Submit profile */
const handleAddressSubmitedit = (event) => {
	event.preventDefault();
		const validationErrors = validateaddressprofileedit(addressedit);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let id=addressedit.idedit;
			let title=addressedit.titleedit;
			let street_name=addressedit.street_nameedit;
			let state_id=addressedit.state_idedit;
			let city_id=addressedit.city_idedit;
			let latitude=addressedit.latitudeedit;
			let longitude=addressedit.longitudeedit;
			let zip_code=addressedit.zip_codeedit;
			

			let formData = new FormData(); 
			formData.append('token', localStorage.getItem("token"));
			formData.append('id', id);
			formData.append('title', title);
			formData.append('street_name', street_name);
			formData.append('state_id', state_id);
			formData.append('city_id', city_id);
			formData.append('latitude', latitude);
			formData.append('longitude',longitude);
			formData.append('zip_code', zip_code);
			
			API.post('update_address', formData)
                .then(response => {
                if(response.data.status === 0){
                    setError(response.data.message);
                    setLoading(false); 
					setShowedit(false);
                }else{
					setDisaddress(response.data.data);
					setLoading(false);
				    setShowedit(false);
					setAddressedit({titleedit:'',street_nameedit:'',state_idedit:'',city_idedit:'',latitudeedit:'',longitudeedit:''});
					setAddress1edit("");
					
                }                  
            }).catch(error => {
                 setLoading(false);
				 setError("Something went wrong. Please try again later.");
				 setShowedit(false);
			});  
		}
}
/* End Edit Address Submit profile */

const delete_data = async () => {
	  let formData = new FormData();
		formData.append('token', localStorage.getItem("token"));
		formData.append('type', 'customer');
		API.post('delete_account', formData)
			.then(response => {
				setLoading1(false);
			if(response.data.status === 0){
			
				gettoastwarning(response.data.message,'');
				setLoading1(false); 
			}else{
				removeUserSession();
				gettoastsuccess(response.data.message,'/');
			}                  
		}).catch(error => {
			 setLoading1(false);
			  //alert("Something went wrong. Please try again later.");
			  gettoastwarning("Something went wrong. Please try again later.",'');
		});  
  }

  const delete_confirm = async () => {
		setShowDelete(true);
  }
	const handleCloseDelete = () => setShowDelete(false);
	
	const handleDeleteLogin  = async () => {
			setShowDelete(false);
			setShowDeleteLogin(true);
	  }
	const handleCloseDeleteLogin = () => setShowDeleteLogin(false);

/* Start Value set */
const handleInputChangeLogin = (event) => {
  event.persist();
  setInputsLogin(inputslogin => ({...inputslogin, [event.target.name]: event.target.value}));       
}
/* End Value set */

/* Start Password Visible */
 const handleClickShowPassword2 = () => {
        setValues2({ ...values2, showPassword: !values2.showPassword });
 }
/* End Password Visible */
	
//Start Login check
const handleSubmitLogin = (event) => {
	event.preventDefault();
	
	const validationErrors = validatelogin(inputslogin);
	const noErrors = Object.keys(validationErrors).length === 0;

	setErrors({});
	setErrors(validationErrors);
	
	if(noErrors){
		setLoading1(true);
		let email_or_phone=inputslogin.email_or_phone;
		let password=inputslogin.password;
		
		let formData = new FormData(); 
		formData.append('email_or_phone', email_or_phone);
		formData.append('password', password);
		formData.append('type', 'customer');
		API.post('login', formData)
			.then(response => {
				
			if(response.data.status === 0){
				gettoastwarning('Your ID and Password do not match our record. Please try again.','');
				setLoading1(false); 
			}else{
			   
			   //setLoading(false);
			   //console.log(response.data.data);
			   
			  delete_data();
			}                  
		}).catch(error => {
			 setLoading1(false);
			 gettoastwarning("Something went wrong. Please try again later.",'');
		});  
	}
}
//End Login check

    return (
        <>
        {/* ========== User Profile Page Start ========== */}
          <section className="gen-main">
		  <ToastContainer />
              <Row className="maincnttpnw mx-0">
                <Col xs={2} md={2} className="align-self-center px-0">
                <button onClick={() => navigate(-1)} className="back-btn">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                </Col>
                <Col xs={8} md={8} className="align-self-center text-center"><h3>My Profile</h3></Col>
                <Col xs={2} md={2} className="align-self-center"><p></p></Col>
              </Row>
               <Container>
                <Row md={12} className="genscrn">
                  <Col>
                     <Row md={12} className="maincntsrcn">
					 {error && (
						<div className="alert alert-danger">
						  {error}
						</div>)}
                      <Col className="px-0">
                      <Col className="prflupld">
                        <div className="avatar-upload">
                          <div className="avatar-edit">
                              <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={handleChange}/>
                              <label htmlFor="imageUpload"></label>
                          </div>
                          <div className="avatar-preview">
                            <div >
							{image.preview ? (
                          <> 
							<img src={image.preview} id="imagePreview" className="img-fluid img-mer-pro" alt="Logo"/>
						  </>
							) : (
							  <>
							<img src="assets/images/usr-img.png" id="imagePreview" className="img-fluid img-mer-pro" alt="Logo"/>
						</>
                          )}
							</div>
                          </div>
                          </div>
						  <Form.Label className="text-center w-100">Customer ID #{inputs.customers_id}</Form.Label>
                      </Col>
                         <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                        <Form.Group className="mb-3">
                          <Form.Label>Your Name<span></span></Form.Label>
                          <Form.Control type="text" name="name" placeholder="Enter your name" value={inputs.name} onChange={handleInputChange}/>
						  {errors.name && (<p className="text-danger">{errors.name}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                        <Form.Label>Gender<span></span></Form.Label>
                        <Form.Select name="gender" aria-label="Default select example" value={inputs.gender} onChange={handleInputChange}>
                          <option value="">Select Gender</option>
                           <option value="Male">Male</option>
                           <option value="Female">Female</option>
                           <option value="Other">Other</option>
                        </Form.Select>
						{errors.gender && (<p className="text-danger">{errors.gender}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Phone Number <span></span></Form.Label>
                         
						  <Form.Control type="tel" name="mobile" id="phone" placeholder="000 000 0000" ref={inputRef} onChange={formatAsPhoneNumber} value={inputs.mobile}/>
						  {errors.mobile && (<p className="text-danger">{errors.mobile}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Date of Birth<span></span></Form.Label>
                          <Form.Control type="date" name="dob" placeholder="dd/mm/yy" value={inputs.dob} onChange={handleInputChange} max={minDateStr}/>
						  {errors.dob && (<p className="text-danger">{errors.dob}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Address <span></span></Form.Label>
                          {disaddress.length > 0 && disaddress.map((item,index) => {
                          return(
						  <Col md={12} xs={12} className="mcusbx">
						   <Row className="mx-0"> 
							  <Col md={10} xs={9} className="align-self-center mcusbx-txtcnt px-0 ps-md-0 address-main-box">
							  <p>{item.title}</p>
							  <p>{item.street_name}</p>
							  <p>{item.state_id}</p>
							  <p>{item.city_id}</p>
							  <p>{item.zip_code}</p>
							  </Col>
							  <Col md={2} xs={3} className="align-self-center text-end px-0">
							  
							  <NavLink to="#" onClick={() => handleEdit(item.id)}>
								 <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"/></svg>
							  </NavLink>
							  
							  {index >0 && (<NavLink to="#" onClick={() => handleDelete(item.id)}>
								 <img src= {'assets/images/merchant/delete.png'} className="img-fluid" alt="Delete"/>
							  </NavLink>)}
							  </Col>
						   </Row>
						   </Col>
						    )
                          })}  
						  {errors.address && (<p className="text-danger">{errors.address}</p>)}
                        </Form.Group>
                        <Row className="mb-3 mx-0 justify-content-end adrs-add-btn">
                          <Col xs="6" md="4">
                            <NavLink to="#" onClick={handleShow}><Button variant="primary" type="button">Add Address</Button> </NavLink>
                          </Col>
                        </Row>
                        <Form.Group className="mb-3">
                          <Form.Label>User Name</Form.Label>
                          <Form.Control type="text" name="user_name" placeholder="Enter user name" value={inputs.user_name} onChange={handleInputChange}/>
						  {errors.user_name && (<p className="text-danger">{errors.user_name}</p>)}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control type="text" name="email" placeholder="Enter your email address" value={inputs.email} onChange={handleInputChange}/>
						  {errors.email && (<p className="text-danger">{errors.user_name}</p>)}
                        </Form.Group>
				       {logintype && (
						<>
                        <Form.Group className="mb-3 pswinpt">
                         <Form.Label>Change Password<span></span></Form.Label>
                        <Form.Control type={values.showPassword ? "text" : "password"} name="password" id="enpswd" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password}/>
                          {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
                        {errors.password && (<p className="text-danger">{errors.password}</p>)}
						
						</Form.Group>
                        <Form.Group className="mb-3 pswinpt">
                         <Form.Label>Re-enter Password<span></span></Form.Label>
                        <Form.Control type={values1.showPassword ? "text" : "password"} name="password_confirmation" id="renpswd" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password_confirmation}/>
                          {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
							{errors.password_confirmation && (<p className="text-danger">{errors.password_confirmation}</p>)}
						</Form.Group>
						</>
						)}
                        <Row>
                          <Col className="btn-dv">
                           <Button type="submit">Save</Button>
                          </Col>
                          <div className="mt-3 mb-3 btn-dv"><NavLink to="/payment-after-login"><button type="button" className="btn">Payment Setup</button></NavLink></div>                          
                        <div className=" mb-3 btn-dv">
						<NavLink to="#"><Button variant="" type="submit" onClick={() => delete_confirm()}>Delete My Account</Button></NavLink>
					 </div>
						</Row>
                      </Form>
                      </Col>
                     </Row>
                  </Col>
            
                </Row>
				{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
               </Container>
          </section>
          {/* ========== User Profile Page En ========== */}
		  {/* ========== Start User Address Modal ========== */}
		  <Modal show={show} onHide={handleClose} className="popupwrngcnt">
            <Modal.Header closeButton>
               <h3 className="mdlttltxt">Add Address</h3>
            </Modal.Header>
            <Modal.Body>
               <Form method="post" onSubmit={handleAddressSubmit} enctype="multipart/form-data"> 
               <Col md={12} xs={12} className="ppinpt">
               
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Title <span>*</span></Form.Label>
					 <Form.Control  type="text" name="title" placeholder="ex Home " value={address.title} onChange={handleAddressInputChange}/>
					 {errors.title && (<p className="text-danger">{errors.title}</p>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Address<span>*</span></Form.Label>
					 
					 <PlacesAutocomplete value={address1} onChange={setAddress1}  onSelect={handleSelect} searchOptions={searchOptions}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          
						  <Form.Control type="location" {...getInputProps({ placeholder: 'Enter address' })} placeholder="Name and street name" />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
					{errors.street_name && (<p className="text-danger">{errors.street_name}</p>)}
                  </Form.Group>
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>City <span>*</span></Form.Label>
					 <Form.Control  type="text" name="city_id" placeholder="city" value={address.city_id} onChange={handleAddressInputChange}/>
                    
					{errors.city_id && (<p className="text-danger">{errors.city_id}</p>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>State <span>*</span></Form.Label>
					 <Form.Control  type="text" name="state_id" placeholder="state" value={address.state_id} onChange={handleAddressInputChange}/>
					{errors.state_id && (<p className="text-danger">{errors.state_id}</p>)}
                  </Form.Group>
				  
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Zip code <span>*</span></Form.Label>
					 <Form.Control name="zip_code" placeholder="Zip code" value={address.zip_code} onChange={handleAddressInputChange}/>
					 {errors.zip_code && (<p className="text-danger">{errors.zip_code}</p>)}
                  </Form.Group>
               </Col>
               <Col md={12} xs={12} className="mb-3 text-center">
                <div className="mb-3 btn-dv"><button type="submit" className="btn">Submit</button></div>
               </Col>
			   </Form>
            </Modal.Body>
            
         </Modal>
		  {/* ========== End User Address Modal ========== */}
		  {/* ========== Start User Address Edit Modal ========== */}
		  <Modal show={showedit} onHide={handleCloseedit} className="popupwrngcnt">
            <Modal.Header closeButton>
               <h3 className="mdlttltxt">Edit Address</h3>
            </Modal.Header>
            <Modal.Body>
               <Form method="post" onSubmit={handleAddressSubmitedit} enctype="multipart/form-data"> 
               <Col md={12} xs={12} className="ppinpt">
               
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Title <span>*</span></Form.Label>
					 <Form.Control  type="text" name="titleedit" placeholder="ex Home " value={addressedit.titleedit} onChange={handleAddressInputChangeedit}/>
					 {errors.titleedit && (<p className="text-danger">{errors.titleedit}</p>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Address<span>*</span></Form.Label>
					 
					 <PlacesAutocomplete value={address1edit} onChange={setAddress1edit}  onSelect={handleSelectedit} searchOptions={searchOptions}>
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          
						  <Form.Control type="location" {...getInputProps({ placeholder: 'Enter address' })} placeholder="Name and street name" />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                              };
                              return (
                                <div {...getSuggestionItemProps(suggestion, { style })}>
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
					{errors.street_nameedit && (<p className="text-danger">{errors.street_nameedit}</p>)}
                  </Form.Group>
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>City <span>*</span></Form.Label>
                      <Form.Control  type="text" name="city_idedit" placeholder="city" value={addressedit.city_idedit} onChange={handleAddressInputChangeedit}/>
					{errors.city_idedit && (<p className="text-danger">{errors.city_idedit}</p>)}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>State <span>*</span></Form.Label>
                     <Form.Control  type="text" name="state_idedit" placeholder="state" value={addressedit.state_idedit} onChange={handleAddressInputChangeedit}/>
					{errors.state_idedit && (<p className="text-danger">{errors.state_idedit}</p>)}
                  </Form.Group>
				  
				  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Zip code <span>*</span></Form.Label>
					 <Form.Control name="zip_codeedit" placeholder="Zip code" value={addressedit.zip_codeedit} onChange={handleAddressInputChangeedit}/>
					 {errors.zip_codeedit && (<p className="text-danger">{errors.zip_codeedit}</p>)}
                  </Form.Group>
               </Col>
               <Col md={12} xs={12} className="mb-3 text-center">
                <div className="mb-3 btn-dv"><button type="submit" className="btn">Submit</button></div>
               </Col>
			   
			   </Form>
            </Modal.Body>
            
         </Modal>
		  {/* ========== End User Address Edit Modal ========== */}
		  {/* ========== Delete Popup Start ========== */}
                    <Modal show={showdelete} onHide={handleCloseDelete} className="popupwrngcnt">
                       <Modal.Header>
                         <p>If you click on Yes, ALL of your data will be deleted.</p>
                       </Modal.Header>
                       <Modal.Body>
					   <h3 className="mdlttltxt mb-4">Do you want to delete your Account?</h3>
                          <Row>
                             <Col className="btn-clr">
                             <NavLink to="#" onClick={handleDeleteLogin}><Button variant="">Yes</Button></NavLink>
                             </Col>
                             <Col className="btn-dv">
                             <NavLink to="#" onClick={handleCloseDelete}><Button variant="">No</Button></NavLink>
                             </Col>
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Popup End ========== */}
				  {/* ========== Delete Login Popup Start ========== */}
                    <Modal show={showdeletelogin} onHide={handleCloseDeleteLogin} className="popupwrngcnt">
                       <Modal.Header closeButton>
                          <h3 className="mdlttltxt">Login</h3>
                       </Modal.Header>
                       <Modal.Body>
                          <Row>
                             <Col>
							 
								<Form method="post" onSubmit={handleSubmitLogin} enctype="multipart/form-data">
								   <Form.Group className="mb-3">
									  <Form.Label>Email or Phone<span>*</span></Form.Label>
									  <Form.Control type="text" name="email_or_phone" placeholder="Enter email or phone number" onChange={handleInputChangeLogin} value={inputslogin.email_or_phone} autoComplete="off"  />
									  {errors.email_or_phone && (<p className="text-danger">{errors.email_or_phone}</p>)}
								   </Form.Group>
								   <Form.Group className="mb-3 pswinpt">
									  <Form.Label>Password<span>*</span></Form.Label>
									  
									  <Form.Control type={values2.showPassword ? "text" : "password"} name="password" id="password" placeholder="At least 8 characters" onChange={handleInputChangeLogin} value={inputslogin.password} autoComplete="off" />
									  {values2.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword2}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword2}></i>}
									  {errors.password && (<p className="text-danger">{errors.password}</p>)}
								   </Form.Group>
								   
								   <div className="mb-3 btn-dv">
									  <Button type="submit">Login</Button>
								   </div>
								   {loading1 && (
								   <div className="loadingClass">
										<img src={loader} alt="loader"/>
									</div>
								   )}  
								</Form>
                             </Col>
                            
                          </Row>
                       </Modal.Body>
                       <Modal.Footer className="d-none"></Modal.Footer>
                    </Modal>
                  {/* ========== Delete Login Popup End ========== */}
        </>
    )
}
export default UserPrfl;