import React, { useEffect, useState,useRef  } from 'react';
import { Row, Col, Container, Form,Modal } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import './UserSgup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate,useLocation } from 'react-router-dom';
import loader from '../../../assests/loader.gif';
import {validate} from '../../../Utils/validate';
import API from '../../../Utils/Api';
import 'intl-tel-input/build/css/intlTelInput.css'; // Import the CSS
import intlTelInput from 'intl-tel-input';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { gettoastsuccess,gettoastwarning } from '../../../Utils/Common';

const UserSgup = () => {
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
var key_id='';
if(searchParams.get('key'))
{
	key_id = searchParams.get('key');
}
var register_type='';
if(searchParams.get('type'))
{
	register_type = searchParams.get('type');
}
const currentDate = new Date();

  // Calculate the date 18 years ago from the current date
  const minDate = new Date(currentDate);
  minDate.setFullYear(currentDate.getFullYear() - 18);

  // Convert the minDate to a string in the "yyyy-MM-dd" format
  const minDateStr = minDate.toISOString().split('T')[0];


const inputRef = useRef(null);
	
const navigate = useNavigate();
const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
const [values1, setValues1] = useState({
        password: "",
        showPassword: false,
    });
const [error,setError] = useState();
const [success,setSuccess] = useState();	
const [inputs,setInputs] = useState({name:'',email:'',mobile:'',dob:'',country_short_code:'us',country_code:'1',password:'',password_confirmation:'',user_name:'',key_id:'',register_type:''});
const [errors,setErrors] = useState({});
const [loading,setLoading] = useState(false);
const [show, setShow] = useState(false); 
const [terms, setTerms] = useState('<p></p>');
const [showprvcy, setShowPrvcy] = useState(false); 
const [privacy, setPrivacy] = useState('<p></p>');


const [textboxdis, setTextboxdis] = useState(false); 

useEffect(() => {
      document.title = `User Signup | Blue Faucet`;
      document.getElementById('bodywrap').className='sguppgmain user-icn';
  
  
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth', 
    }); 
	/* Start get terms */
		API.get('general')
			.then(response => {
				setLoading(false);
			if(response.data.status === 0){
				
			}else{
			  setTerms(response.data.data.terms);
			}                  
		}).catch(error => {
			 
		});  
	/* End get terms */
	
	/* Start get privacy policy */
		API.get('general')
			.then(response => {
				
			if(response.data.status === 0){
				
			}else{
			  setPrivacy(response.data.data.privacy);
			}                  
		}).catch(error => {
			 
		});  
	/* End get privacy policy */
	
	if(key_id!=='')
	{
		let formData = new FormData(); 
			formData.append('token', key_id);
			formData.append('register_type', register_type);
			API.post('get_customer_invitation_by_id', formData)
				.then(response => {
				if(response.data.status === 0){
					
				}else{
					setTextboxdis(true);
					setInputs({name:response.data.data.name,email:response.data.data.email,mobile:response.data.data.mobile,dob:'',country_short_code:response.data.data.short_code,country_code:response.data.data.country_code,password:'',password_confirmation:'',user_name:response.data.data.owner_name,key_id:key_id,register_type:register_type});
				}                  
			}).catch(error => {
				 
			});
			var key_id_lbl='key_id';
			setInputs(inputs => ({...inputs, [key_id_lbl]: key_id}));
			
			var register_type_lbl='register_type';
			setInputs(inputs => ({...inputs, [register_type_lbl]: register_type}));
    }			

	const input = inputRef.current;
    const iti = intlTelInput(input, {
	  initialCountry:"us",
      separateDialCode:true,
    });

    // Listen for changes
    input.addEventListener('countrychange', () => {
      const selectedCountryData = iti.getSelectedCountryData();
	  var country_code_lbl='country_code';
	  setInputs(inputs => ({...inputs, [country_code_lbl]: selectedCountryData.dialCode}));
	  var country_short_code_lbl='country_short_code';
	  setInputs(inputs => ({...inputs, [country_short_code_lbl]: selectedCountryData.iso2}));
    });

    // Clean up when the component unmounts
    return () => {
      iti.destroy();
    };

},[key_id,register_type])   

	/* Start Submit Sign */
	const handleSubmit = (event) => {
		
		event.preventDefault();
		
		const validationErrors = validate(inputs);
        const noErrors = Object.keys(validationErrors).length === 0;
		setError('');
		setSuccess('');
		setErrors({});
        setErrors(validationErrors);
		
		if(noErrors){
			setLoading(true);
			let name=inputs.name;
			let email=inputs.email;
			let mobile=inputs.mobile;
			let dob=inputs.dob;
			let country_code=inputs.country_code;
			let password=inputs.password;
			let password_confirmation=inputs.password_confirmation;
			let user_name=inputs.user_name;
			let country_short_code=inputs.country_short_code;
			
			let formData = new FormData(); 
			formData.append('name', name);
			formData.append('email', email);
			formData.append('mobile', mobile);
			formData.append('dob', dob);
			formData.append('country_code', country_code);
			formData.append('password', password);
			formData.append('password_confirmation',password_confirmation);
			formData.append('user_name', user_name);
			formData.append('country_short_code', country_short_code);
			formData.append('key_id', inputs.key_id);
			formData.append('register_type', inputs.register_type);
			API.post('signup', formData)
                .then(response => {
                if(response.data.status === 0){
                    //alert(response.data.message);
					gettoastwarning(response.data.message,'');
					setLoading(false); 
					
                }else{
					//alert(response.data.message);
					gettoastsuccess(response.data.message,'/login');					
                    setLoading(false); 
				   //navigate('/login');
                }                  
            }).catch(error => {
                 setLoading(false);
				  setError("Something went wrong. Please try again later.");
			});  
		}
   } 
  /* End Submit Sign */
  /* Start Value set */
  const handleInputChange = (event) => {
      event.persist();
      setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));       
    }
  /* End Value set */
  
  /* Start Password Visible */
  const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    }
    const handleClickShowPassword1 = () => {
        setValues1({ ...values1, showPassword: !values1.showPassword });
    }
  /* End Password Visible */
/* Start terms modal */
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);	
/* End terms modal */
/* Start privacy policy  modal */
const handleClosePrvcy = () => setShowPrvcy(false);
const handleShowPrvcy = () => setShowPrvcy(true);	
/* End privacy policy modal */
/* Start Phone Number */
const formatAsPhoneNumber = (event) => {
	event.persist();
	var value=event.target.value;
	value = value.replace(/ /g, '');
	value = value.replace(/\D/g, '');
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	
	if(value.length > 3 && value.length < 7)
	{
	    value = value.slice(0, 3) + ' ' + value.slice(3, 6);
	}else if(value.length > 6)
	{
		
		value = value.slice(0, 3) + ' ' + value.slice(3, 6) + ' ' + value.slice(6);
	}
	setInputs(inputs => ({...inputs, [event.target.name]: value})); 
}
/* End Phone Number */
 
    return (
        <>
          {/* ========== Signup Page Start ========== */}
           <section className="gen-wrap gen-main">
		   <ToastContainer />
                <Row className="maincnttpnw mx-0">
                 <Col xs={2} md={2} className="align-self-center px-0">
                  <button onClick={() => navigate(-1)} className="back-btn d-none">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M6.87756 0.514167C6.63045 0.637723 0.150184 7.12652 0.0607126 7.33955C-0.0202375 7.54406 -0.0202375 7.8849 0.0607126 8.08941C0.107578 8.20018 1.11306 9.23123 3.39671 11.5234C6.83496 14.9659 6.86904 15 7.23971 15C7.52516 15 7.72115 14.9148 7.92565 14.7018C8.22815 14.395 8.28354 14.0158 8.08755 13.6366C8.04495 13.5557 6.92869 12.4053 5.60366 11.0803L3.1922 8.6731L11.2744 8.6518L19.3609 8.63049L19.5526 8.52398C19.6549 8.46433 19.7998 8.33226 19.8722 8.23426C19.9872 8.06384 20 8.01698 20 7.71448C20 7.41198 19.9872 7.36511 19.8722 7.19469C19.7998 7.0967 19.6549 6.96462 19.5526 6.90498L19.3609 6.79846L11.2744 6.77716L3.1922 6.75586L5.62497 4.32735C7.26527 2.68704 8.08329 1.84346 8.13868 1.72842C8.34745 1.28532 8.15146 0.744236 7.69985 0.509907C7.48682 0.399133 7.10337 0.403393 6.87756 0.514167Z" fill="white"/>
                   </svg>
                </button>
                 </Col>
                 <Col xs={8} md={8} className="align-self-center text-center">
                    <img src={`${process.env.REACT_APP_BASE_URL}assets/images/logo.svg`} className="img-fluid w-75 mb-3" alt="Logo"/>
                    <p>Your local Market place for <span className="fw-bold">Buy/Sell and Services</span></p>
                 </Col>
                 <Col xs={2} md={2} className="align-self-center"><p></p></Col>
                </Row>
            <Container>
                <Row className="genscrn">
                <Col md={12} lg={12} xl={12} xxl={12} className="toptxtcnt px-0">
                <div className="maincntsrcn">
					{error && (
						<div className="alert alert-danger">
						  {error}
						</div>)}
					  {success && (
						<div className="alert alert-success">
						  {success}
						</div>)}
                   <Form method="post" onSubmit={handleSubmit} enctype="multipart/form-data">
                    <Form.Group className="mb-3">
                      <Form.Label>Your Name<span>*</span></Form.Label>
                      <Form.Control type="text" name="name" placeholder="Enter full name" onChange={handleInputChange} value={inputs.name} />
					  {errors.name && (<p className="text-danger">{errors.name}</p>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email<span>*</span></Form.Label>
                      <Form.Control type="text" name="email" placeholder="Enter email address" onChange={handleInputChange} value={inputs.email} disabled={textboxdis} />
					  {errors.email && (<p className="text-danger">{errors.email}</p>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone Number <span>*</span></Form.Label>					  
					  <Form.Control type="tel" name="mobile" id="phone" placeholder="000 000 0000" ref={inputRef} onChange={formatAsPhoneNumber} value={inputs.mobile} disabled={textboxdis} />
					  {errors.mobile && (<p className="text-danger">{errors.mobile}</p>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Date of Birth</Form.Label>
                      <Form.Control type="date" name="dob" placeholder="dd/mm/yy" onChange={handleInputChange} value={inputs.dob}  max={minDateStr} />
					  {errors.dob && (<p className="text-danger">{errors.dob}</p>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>User Name<span></span></Form.Label>
                      <Form.Control type="text" name="user_name" placeholder="Enter user name" onChange={handleInputChange} value={inputs.user_name}/>
					  {errors.user_name && (<p className="text-danger">{errors.user_name}</p>)}
                    </Form.Group>
                    <Form.Group className="mb-3 pswinpt">
                      <Form.Label>Password<span>*</span></Form.Label>
                      <Form.Control type={values.showPassword ? "text" : "password"} name="password" id="enpswd" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password}/>
                      {values.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword}></i>}
					  {errors.password && (<p className="text-danger">{errors.password}</p>)}
					  
					</Form.Group>
                    <Form.Group className="mb-3 pswinpt">
                      <Form.Label>Re-enter password<span>*</span></Form.Label>
                      <Form.Control type={values1.showPassword ? "text" : "password"} name="password_confirmation" id="renpswd" placeholder="At least 8 characters" onChange={handleInputChange} value={inputs.password_confirmation}/>
                      {values1.showPassword ? <i className="toggle-password eyeshw" onClick={handleClickShowPassword1}></i> : <i className="toggle-password eyehide" onClick={handleClickShowPassword1}></i>}
						{errors.password_confirmation && (<p className="text-danger">{errors.password_confirmation}</p>)}
					</Form.Group>
                    <Form.Group className="mb-3 ckbox">
                      <Form.Check aria-label="option 1" defaultChecked={true}/>
                      <Form.Label>I agree with <NavLink to="#" onClick={handleShow}> User Agreement </NavLink> and <NavLink to="#" onClick={handleShowPrvcy}>Privacy Policy</NavLink></Form.Label>
                    </Form.Group>
                    <div className="mb-3 btn-dv"><button type="submit" className="btn">Sign Up</button></div>
                    <div className="snguplsttxt text-center"><p>Have an account? <NavLink to="/login">Login</NavLink></p></div>
                    </Form>
                    </div>
                </Col>
                </Row>
				{loading && (
			   <div className="loadingClass">
					<img src={loader} alt="loader"/>
				</div>
			   )}  
               </Container>
            </section>
            {/* ========== Sigup Page Start ========== */}
			{/* ========== Terms Modal Start ========== */}
			<Modal show={show} onHide={handleClose} className="popupwrngcnt">
				<Modal.Header closeButton>
				   <h3 className="mdlttltxt">Terms and Conditions</h3>
				</Modal.Header>
				<Modal.Body>
				   <Col md={12} xs={12} className="ppinpt mb-3">
					<div dangerouslySetInnerHTML={{__html: terms}} />
				   </Col>
				</Modal.Body>
				<Modal.Footer className="d-none">
				   
				</Modal.Footer>
			 </Modal>
			 {/* ========== Terms Modal End ========== */}
			 {/* ========== Privacy Policy Modal Start ========== */}
			<Modal show={showprvcy} onHide={handleClosePrvcy} className="popupwrngcnt">
				<Modal.Header closeButton>
				   <h3 className="mdlttltxt">Privacy Policy</h3>
				</Modal.Header>
				<Modal.Body>
				   <Col md={12} xs={12} className="ppinpt mb-3">
					<div dangerouslySetInnerHTML={{__html: privacy}} />
				   </Col>
				   
				</Modal.Body>
				<Modal.Footer className="d-none">
				   
				</Modal.Footer>
			 </Modal>
			 {/* ========== Privacy Policy Modal End ========== */}
        </>
    )
}
export default UserSgup;